import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./PageSection.module.scss";
import { Container } from "../Container/Container";
import { Appear } from "../Appear/Appear";

export interface PageSectionProps {
	children: ReactNode;
	id: string;
	/**
	 * The text (or node) for the heading. Rendered as an h2.
	 */
	heading?: ReactNode;
	/**
	 * The type/style of the heading.
	 * Subtle is smaller text, kind of like body text.
	 * Impact is brand blue.
	 */
	headingType?: false | "subtle" | "impact";
	isVisuallyHiddenHeading?: boolean;
	lead?: ReactNode;
	isInverse?: boolean;
}

export const PageSection: FC<PageSectionProps> = ({
	id,
	heading,
	headingType = false,
	isVisuallyHiddenHeading = false,
	lead,
	children,
	isInverse = false,
}) => (
	<section
		aria-labelledby={id}
		className={classNames(styles.section, { [styles.isInverse]: isInverse })}
	>
		<Container>
			<Appear>
				<h2
					id={id}
					className={classNames(styles.heading, {
						[styles.isSubtle]: headingType === "subtle",
						[styles.isImpact]: headingType === "impact",
						visuallyHidden: isVisuallyHiddenHeading,
					})}
				>
					{heading}
				</h2>
				{lead && <p className={styles.lead}>{lead}</p>}
			</Appear>
			{children}
		</Container>
	</section>
);
