import React, { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from "classnames";
import { PartialShowcase } from "../../types/types";
import styles from "./Cards.module.scss";
import { RichText } from "prismic-reactjs";

export interface ShowcaseCardProps {
	content: PartialShowcase;
}

export const ShowcaseCard: FC<ShowcaseCardProps> = ({
	content: {
		data: { image, title, is_featured, author, summary, link },
	},
}) => {
	return (
		<article className={styles.card}>
			<div className={styles.imageContainer}>
				<GatsbyImage
					image={image.fluid}
					className={styles.image}
					alt={image.alt || title}
				/>
				{is_featured && (
					<span className={classnames([styles.featuredFlag, styles.game])}>
						Featured game
					</span>
				)}
			</div>
			<div className={styles.text}>
				<h2 className={styles.heading}>
					<a
						href={link.url}
						className={styles.link}
						target="_blank"
						rel="noreferrer"
					>
						{title}
					</a>
				</h2>
				{author?.document?.data?.name && (
					<p className={styles.author}>By {author.document.data.name}</p>
				)}
				{summary?.richText && <RichText render={summary.richText} />}
				{link?.url && (
					<p>
						<a href={link.url} tabIndex={-1} target="_blank" rel="noreferrer">
							Play game
							<span className="visuallyHidden">: {title}</span>
						</a>
					</p>
				)}
			</div>
		</article>
	);
};
