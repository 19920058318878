import React, { FC } from "react";
import Slider from "react-slick";
import slugify from "slugify";
import { PageSection } from "../PageSection/PageSection";
import { Button } from "../Button/Button";
import styles from "./CardCarousel.module.scss";
import "./CardCarousel.scss";

export interface CardCarouselProps {
	children: React.ReactNode;
	heading: string;
	cta?: string;
	ctaLink?: string;
}

export const CardCarousel: FC<CardCarouselProps> = ({
	heading,
	children,
	cta,
	ctaLink,
}) => {
	const settings = {
		useTransforms: true,
		centerMode: true,
		arrows: false,
		speed: 333,
		dots: true,
		className: "sliderCard",
		infinite: true,
		slidesToShow: 3,
		variableHeight: true,
		slidesToScroll: 1,
		initialSlide: 0,
		accessibility: true,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<PageSection
			id={slugify(heading, { lower: true, strict: true })}
			heading={heading}
		>
			<Slider {...settings}>{children}</Slider>
			{cta && (
				<p className={styles.cta}>
					<Button to={ctaLink}>{cta}</Button>
				</p>
			)}
		</PageSection>
	);
};
